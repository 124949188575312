<template>
  <router-link :to="url" tag="li" class="w-full relative rounded grey p-2 mb-3">
    <!-- <div class="w-full relative rounded grey p-2 mb-2" @click="buildingClick"> -->
    <div class="grid grid-cols-9 gap-5">
      <div class="col-span-2 text-white font-extrabold text-20 my-auto p-2">
        {{ name }}
      </div>
      <div class="col-span-1 text-white font-extrabold text-20 border-left my-auto p-2">
        {{ units }}
      </div>
      <div class="col-span-1 text-white font-extrabold text-20 border-left my-auto p-2">
        {{ applianceCounts.total }}
      </div>
      <div class="col-span-1 font-extrabold text-20 text-green border-left my-auto p-2">
        {{ applianceCounts.online }}
      </div>
      <div class="col-span-1 font-extrabold text-20 text-yellow border-left my-auto p-2">
        {{ applianceCounts.offline }}
      </div>
      <div
        class="arrow-cta col-span-1 font-extrabold text-20 border-left my-auto p-2"
        :class="{
          'text-red': applianceCounts.errors > 0,
          'text-ltgrey': applianceCounts.errors == 0,
        }"
      >
        {{ applianceCounts.errors }}<span class="right-2"></span>
      </div>
    </div>
  </router-link>
</template>

<script>
  export default {
    props: {
      name: {
        type: String,
        default: 'Building 1',
      },
      units: {
        type: Number,
        default: 40,
      },
      applianceCounts: {
        type: Object,
        default: () => ({
          online: 0,
          offline: 0,
          errors: 0,
        }),
      },
      url: {
        type: String,
        default: '',
      },
    },
    components: {},
    data() {
      return {};
    },
    computed: {},
    mounted() {},
    watch: {},
    methods: {
      buildingClick: function() {
        //
      },
    },
  };
</script>
<style lang="scss" scope>
  @import '../styles/typovars';
  @import '../styles/shared';
  .text-ltgrey {
    color: #c3c3c3;
  }
  .rounded {
    cursor: pointer;
  }
</style>
