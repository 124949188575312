<template>
  <section>
    <propertiesPageHeader
      :name="property.name"
      :address="property.address"
      :units="property.units"
      :applianceCounts="property.applianceCounts"
      :managerEmail="property.managerEmail"
      :managerName="property.managerName"
    ></propertiesPageHeader>
    <div class="listHeader max-main">
      <div class="grid grid-cols-9 gap-5">
        <div class="col-span-2 pl-3 text-12 pl-3">Name</div>
        <div class="col-span-1 text-12 pl-3">Units</div>
        <div class="col-span-1 text-12 pl-3">Appliances</div>
        <div class="col-span-1 text-12 pl-3">Online</div>
        <div class="col-span-1 text-12 pl-3">Offline</div>
        <div class="col-span-1 text-12 pl-3">Errors</div>
      </div>
      <div class="gradient_spacer relative w-full m-0"></div>
    </div>
    <ul>
      <buildinglisting
        v-for="building in property.buildings"
        :key="building.name"
        :name="building.name"
        :units="building.units"
        :applianceCounts="building.applianceCounts"
        :url="building.url"
      ></buildinglisting>
    </ul>
  </section>
</template>

<script>
  import propertiesPageHeader from '@/components/propertiesPageHeader.vue';
  import buildinglisting from '@/components/propertiesBuildingListItem.vue';
  import { getDataByHostname } from '../utils/dataUtil';
  export default {
    components: { buildinglisting, propertiesPageHeader },
    data() {
      return {
        property: [{ name: '', applianceCounts: {}, buildings: [] }],
      };
    },
    mounted() {
      const dataByHostName = getDataByHostname();
      this.property = dataByHostName?.properties?.[0] || {};
    },
  };
</script>
<style lang="scss" scope>
  @import '../styles/typovars';
</style>
